import { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Grid,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';

import { useGetLeaderboard } from '../../../api/users/useGetLeaderboard';
import { useGetTopTrenches } from '../../../api/users/useGetTopTrenches';
import { useGetTrendingTrenches } from '../../../api/users/useGetTrendingTrenches';
import {
  IdeaIcon,
  KolGamesIcon,
  LearningIcon,
} from '../../../components/icons';
import SearchAi from '../../../components/SeaarchAi';
import ActionCard from '../../../components/shared/Cards/ActionCard';
import { CreatorLeaderboardCard } from '../../../components/shared/Cards/CreatorLeaderBoardCard/CreatorLeaderboardCard';
import { FeedbackBanner } from '../../../components/shared/FeedbackBanner';
import { SortBy, SortByOptions } from '../../../components/shared/SortBy';
import { CreatorLeaderboardCardSkeleton } from '../../../components/Skeletons/CreatorLeaderboardCardSkeleton';
import UserCardsOnboardSteps from '../../../components/UserCardsOnboardSteps';
import {
  LEARN_MORE_KOL_GAMES_LINK,
  LEARN_MORE_LEADERBOARD_RANK_LINK,
} from '../../../constants/externalLinks';
import { EDIT_PROFILE_PATH } from '../../../constants/routings';
import useCurrentUserData from '../../../hooks/auth/useCurrentUserData';
import navigate from '../../../lib/navigate';
import { useProfileSetupProgress } from '../../../providers/ProfileSetupProgressProvider';
import { useGetSubPricesByAddressesBatch } from '../../../web3/hooks/SubscribeRegistry/useGetSubPricesByAddressesBatch';
import { UserType } from '../../../types/UserType';

const USERS_PER_PAGE = 9;

const LeaderboardPagination = ({
  page,
  numberOfCreators,
  onPageChange,
}: {
  page: number;
  numberOfCreators: number;
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}) => {
  const startIndex = (page - 1) * USERS_PER_PAGE + 1;
  const endIndex = Math.min(startIndex + USERS_PER_PAGE - 1, numberOfCreators);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: { xs: 'center', md: 'space-between' },
        mt: 4,
      }}
    >
      <Typography sx={{ display: { xs: 'none', md: 'block' } }}>
        Showing {startIndex} to {endIndex} of {numberOfCreators} trenches
      </Typography>
      <Pagination
        count={Math.ceil(numberOfCreators / USERS_PER_PAGE)}
        page={page}
        onChange={onPageChange}
        color="primary"
        variant="outlined"
        shape="rounded"
      />
    </Box>
  );
};

export function LeaderboardPageContent() {
  const [sortOption, setSortOption] = useState<SortByOptions>(
    SortByOptions.TOP_TRENCHES,
  );
  const [pageSelected, setPageSelected] = useState(1);

  const skip = (pageSelected - 1) * USERS_PER_PAGE;

  const { data: userData, isLoading: isLoadingUserData } = useCurrentUserData();
  const { isProfileSetupCompleted, isLoading: isLoadingProfileSetupCompleted } =
    useProfileSetupProgress();

  useEffect(() => {
    setPageSelected(1);
  }, [sortOption]);

  // Fetch the initial leaderboard data only once
  const { data: leaderboardData, isLoading: isLoadingLeaderboardData } =
    useGetLeaderboard({
      enabled: true,
    });

  const totalTopTrenches = leaderboardData?.total_top_trenches ?? 0;
  const totalTrendingTrenches = leaderboardData?.total_trending_trenches ?? 0;

  // Fetch top creators
  const { data: topCreatorsData, isLoading: isLoadingTopCreators } =
    useGetTopTrenches({
      skip,
      num: USERS_PER_PAGE,
      enabled: sortOption === SortByOptions.TOP_TRENCHES,
    });

  // Fetch rising stars
  const { data: risingStarsData, isLoading: isLoadingRisingStars } =
    useGetTrendingTrenches({
      skip,
      num: USERS_PER_PAGE,
      enabled: sortOption === SortByOptions.TRENDING_TRENCHES,
    });

  const creatorsToDisplay = useMemo(() => {
    return sortOption === SortByOptions.TOP_TRENCHES
      ? topCreatorsData || []
      : risingStarsData || [];
  }, [sortOption, topCreatorsData, risingStarsData]);

  const subPricesByAddressMapResult = useGetSubPricesByAddressesBatch(
    creatorsToDisplay.map((creator) => creator.wallet_address),
  );

  const renderCreators = () => {
    if (
      isLoadingUserData ||
      isLoadingProfileSetupCompleted ||
      isLoadingLeaderboardData ||
      isLoadingTopCreators ||
      isLoadingRisingStars
    ) {
      return Array.from({ length: 9 }).map((_, index) => (
        <CreatorLeaderboardCardSkeleton key={index} />
      ));
    }

    return creatorsToDisplay.map((creator, index) => (
      <CreatorLeaderboardCard
        creator={creator}
        key={index}
        subPriceResult={
          subPricesByAddressMapResult?.data?.[creator.wallet_address]
        }
        subPriceLoading={subPricesByAddressMapResult?.isLoading}
      />
    ));
  };

  const numberOfCreators =
    sortOption === SortByOptions.TOP_TRENCHES
      ? totalTopTrenches
      : totalTrendingTrenches;

  const isNineCardsDisplayed = creatorsToDisplay?.length === USERS_PER_PAGE;

  const handleUpdateProfile = () => {
    navigate(EDIT_PROFILE_PATH);
  };

  const hasUpdateProfileBanner =
    userData &&
    userData.user_type === UserType.CREATOR &&
    (!userData.about || !userData.media?.length);

  const hasFilter = sortOption === SortByOptions.TRENDING_TRENCHES;

  return (
    <Stack
      direction="column"
      sx={{
        flex: 1,
        overflowY: 'auto',
        width: { xs: '100vw', md: '100%' },
        minWidth: { xs: '100vw', md: '1200px' },
        height: '100%',
        pl: { xs: 5, md: 1 },
        pr: { xs: 3, md: 1 },
        '&::-webkit-scrollbar': {
          width: '8px',
          backgroundColor: 'transparent',
        },
        pb: { xs: 20, md: 10 },
        overflowX: 'hidden',
        bgcolor: 'transparent',
      }}
    >
      {hasUpdateProfileBanner && (
        <FeedbackBanner
          type="success"
          title="Add media and about section to your profile"
          body="Share what you offer in your community so more people will want to join and become members!"
          buttonText="Update profile"
          onButtonClick={handleUpdateProfile}
          icon={<IdeaIcon />}
        />
      )}
      {!isProfileSetupCompleted && userData && (
        <UserCardsOnboardSteps isCreator={userData?.user_type === 'CREATOR'} />
      )}
      <Box
        id="creator-leaderboard"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          pt: hasUpdateProfileBanner ? 6 : 0,
          pb: { xs: 2, md: 5 },
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            lineHeight: '38px',
            fontSize: { xs: '24px', md: '32px' },
          }}
        >
          Trenches
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 4,
          width: '100%',
          pb: { xs: 3, md: 6 },
        }}
      >
        <SearchAi
          sx={{
            flexGrow: 1,
            height: '44px',
            p: 0,
            pl: 4,
            alignItems: 'center',
          }}
        />
        <SortBy
          sortOptionSelected={sortOption}
          onSortChange={setSortOption}
          hasFilter={hasFilter}
        />
      </Box>

      <Grid
        container
        spacing={5}
        pt={1}
        pb={{ xs: 3, md: 9 }}
        minHeight={{ xs: 'auto', md: isNineCardsDisplayed ? '1196px' : 'auto' }}
        minWidth={{ xs: 'auto', md: '1280px' }}
      >
        {renderCreators()}
      </Grid>

      <LeaderboardPagination
        numberOfCreators={numberOfCreators}
        page={pageSelected}
        onPageChange={(event, value) => setPageSelected(value)}
      />

      {/* Additional content */}
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: '500',
          lineHeight: '28px',
          pt: { xs: 10, md: 13 },
          pb: { xs: 4, md: 6 },
        }}
      >
        Knowledgebase
      </Typography>
      <Grid container spacing={5} pb={10}>
        <Grid item xs={12} md={6}>
          <ActionCard
            title="The Creator Games"
            description="Support your favorite creators and win together"
            image={<KolGamesIcon />}
            isLong
            comingSoon
          >
            <Button
              variant="outlined"
              onClick={() => window.open(LEARN_MORE_KOL_GAMES_LINK, '_blank')}
            >
              Learn more
            </Button>
          </ActionCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <ActionCard
            title="Altcoinist learning"
            description="How to rank in the leaderboard and earn rewards"
            image={<LearningIcon />}
            isLong
          >
            <Button
              variant="outlined"
              onClick={() =>
                window.open(LEARN_MORE_LEADERBOARD_RANK_LINK, '_blank')
              }
            >
              Learn more
            </Button>
          </ActionCard>
        </Grid>
      </Grid>
    </Stack>
  );
}
