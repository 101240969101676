import { Box, Grid, Skeleton, Typography } from '@mui/material';

import { Trench } from '../../../../api/types/getLeaderboard';
import { GET_CREATOR_PATH } from '../../../../constants/routings';
import { useMainTokenInfo } from '../../../../hooks/useMainTokenInfo';
import formatBigintWithDecimals from '../../../../lib/formatBigintWithDecimals';
import navigate from '../../../../lib/navigate';
import { isVideo } from '../../../../lib/videoValidations';
import { OnchainResult } from '../../../../web3/types';
import {
  CommunityMembersSmall,
  CreatorRevenueSmall,
  SharedRevenueSmall,
} from '../../../icons';
import AvatarWithBorder from '../../AvatarWithBorder';

import { PositionBadge } from './PositionBadge';
import { StatsItem } from './StatsItem';

type ICreatorData = Trench;

interface ICreatorLeaderboardCardProps {
  creator: ICreatorData;
  subPriceResult: OnchainResult<bigint | null>;
  subPriceLoading: boolean;
}
const MAX_FRACTION_DIGITS_FOR_PRICE = 4;

export function CreatorLeaderboardCard({
  creator,
  subPriceResult,
  subPriceLoading,
}: ICreatorLeaderboardCardProps) {
  const { decimals, symbol } = useMainTokenInfo();

  const {
    avatar,
    media,
    username,
    bio,
    rank,
    subs,
    creator_revenue,
    shared_revenue,
  } = creator;

  const handleCreatorNavigation = () => {
    navigate(GET_CREATOR_PATH(username));
  };

  // get price dynamic
  const hasMedia = media && media.length > 0;
  const mediaLink = media?.[0] ?? '';

  const costInEth = formatBigintWithDecimals(
    subPriceResult.result,
    decimals,
    MAX_FRACTION_DIGITS_FOR_PRICE,
  );

  const subPriceTextColor =
    !subPriceResult || subPriceResult.status === 'failure'
      ? 'error'
      : 'text.primary';

  return (
    <Grid item xs={12} md={4}>
      <Box
        sx={{
          border: '1px solid #262829',
          borderRadius: '16px',
          backgroundColor: '#111314',
          transition: 'transform 0.3s ease-in-out',
          height: '100%',
          minHeight: { xs: 'auto', md: '374px' },
          '&:hover': {
            border: '1px solid #383B3D',
            cursor: 'pointer',
          },
        }}
        onClick={handleCreatorNavigation}
      >
        <Box
          sx={{
            height: { xs: hasMedia ? '197px' : 'auto', md: '197px' },
            borderRadius: '12px',
            width: '100%',
            pt: hasMedia ? 0 : 5,
            pl: hasMedia ? 0 : 5,
            display: 'flex',
            alignItems: 'end',
          }}
        >
          {hasMedia ? (
            <Box
              component={isVideo(mediaLink) ? 'video' : 'img'}
              src={mediaLink}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
              }}
            />
          ) : (
            <AvatarWithBorder name={username} src={avatar} size={40} />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            py: 4,
            px: 5,
            gap: 6,
            justifyContent: 'space-between',
            maxHeight: { xs: 'none', md: '175px' },
            height: { xs: 'auto', md: '100%' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                width: '248px',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: '400',
                    lineHeight: '25px',
                    color: '#F0F5FA',
                  }}
                >
                  {username}
                </Typography>
                <PositionBadge rank={rank} />
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 300,
                  color: '#9B9FA3',
                  lineHeight: '17px',
                }}
              >
                {bio}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                textAlign: 'right',
              }}
            >
              {subPriceLoading && (
                <Skeleton animation={false} width={90} height={15} />
              )}

              {!subPriceLoading && (
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '20px',
                  }}
                  color={subPriceTextColor}
                >
                  {costInEth} {symbol}
                </Typography>
              )}

              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 300,
                  color: '#9B9FA3',
                  lineHeight: '17px',
                }}
              >
                /month
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={4}>
            <StatsItem
              icon={<CommunityMembersSmall />}
              title="Community Members"
              value={subs}
            />
            <StatsItem
              icon={<CreatorRevenueSmall />}
              title="Creator Revenue"
              value={creator_revenue}
              isDollar
            />
            <StatsItem
              icon={<SharedRevenueSmall />}
              title="Shared revenue"
              value={shared_revenue}
              isDollar
            />
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
